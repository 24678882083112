import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Home from './Components/Home';
import Deploy from './Components/Deploy';
import DeployOff from './Components/DeployOff';
import Return from './Components/Return';
import ConfirmDeploy from './Components/ConfirmDeploy';
import ConfirmReturn from './Components/ConfirmReturn';
import SentDeploy from './Components/SentDeploy';

import "./App.css"

function App() {
  return (
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sent" element={<SentDeploy />} />
        <Route path="/return" element={<Return />} />
        <Route path="/deploy" element={<Deploy />} />
        <Route path="/deploy-new" element={<DeployOff />} />
        <Route path="/confirm" element={<ConfirmDeploy />} />
        <Route path="/war" element={<ConfirmReturn />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
  );
}
export default App;