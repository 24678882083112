import '../App.css';
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams  } from "react-router-dom";
import { ethers } from "ethers"

import {
  useAddress,
  ConnectWallet,
} from "@thirdweb-dev/react";

import axios from 'axios';

function App() {

  const [searchParams] = useSearchParams();
  const rotaToken = useState(searchParams.get("rota"));

  /* global BigInt */

  const navigate = useNavigate();

  const currentPrice = 0.049;

  const [mintvalue, setMintValue] = useState('');
  const [voucher, setVoucher] = useState('');
  const [addr, setAddress] = useState('');

  const [mintvalueReserved, setMintValueReserved] = useState('');
  const [mode, setMode] = useState('');

  const address = useAddress();

  useEffect(() => {

    if (address) {
      var arrayRotas = JSON.parse(rotaToken[0]);
      setAddress(address);
      checkApproval(address);
      if(arrayRotas.length > 1){
      document.getElementById("rota-token").innerHTML = arrayRotas.length + " APES";
    }
    else{
      document.getElementById("rota-token").innerHTML = arrayRotas.length + " APE";
    }
      getROTAImage(arrayRotas)

    }
  }, [address]);

    const previousAddressRef = useRef();
    useEffect(() => {
      previousAddressRef.current = address;
    }, [address]);
    const previousAddress = previousAddressRef.current;

    useEffect(() => {
      if (previousAddress && !address) {
        navigate("/");
      }
    }, [address, previousAddress]);

  const warContract = "0x259C2FF4e10e7e2B31A2045c93eD8513299c8E04"

  async function getROTAImage(token) {
    let imageUrls = [];
  
    for (var i = 0; i < token.length; i++) {
      let url = `https://files.atsnft.io/token/` + token[i];
      try {
        const response = await axios.get(url);
        imageUrls.push(response.data.image);
      } catch (error) {
        console.log(error);
      }
    }
  
    const imageTags = imageUrls.map(url => `<img id="rota-image" style="height:200px" src="${url}"></img>`);
  
    document.getElementById("rota-images").innerHTML = imageTags.join("");
  }
  

  async function  checkApproval(address){
    const RPC = "https://eth-mainnet.g.alchemy.com/v2/hDbuktulZ87WrMhMeTlr6KdsttWlh2JJ";
    const provider = new ethers.providers.JsonRpcProvider(RPC);
  
    const CONTRACT_ADDRESS_ROTA = new ethers.Contract(rota.address, rota.abi, provider);
  
    var isApproved = await CONTRACT_ADDRESS_ROTA.isApprovedForAll(address, warContract);

    if(!isApproved){
      document.getElementById("war-deployment").innerHTML = "APPROVE APES FOR WAR"
      document.getElementById("approval").style.display=null
      document.getElementById("approval-yes").style.display="none"
    }
    else{
      document.getElementById("approval").style.display="none"
      document.getElementById("approval-yes").style.display=null
    }
  }

  const warbonds = {
    address: "0xcAcb0a5bb1f52F00a14bDA0dc85dE81392B2892B",
    abi: [
      "function balanceOf(address account, uint256 id) public view virtual override returns (uint256)"
    ]
  };

  const war = {
    address: warContract,
    abi: [
      "function getTimeLeft(uint256 tokenId) public view returns (uint256)",
      "function leaveWar(uint256[] calldata tokenIds, uint256[] calldata randomNumbers, uint256[] calldata battleIndices, bytes calldata signature) external nonReentrant",
      "function goToWar(uint256[] calldata tokenIds, uint256[] calldata warBondIds) external",
      "function tokenToTime(uint256) public view returns (uint256)",
      "function tokenToBorder(uint256) public view returns (uint256)"
    ]
  };

    const rota = {
    address: "0xDfB29501b42f63A947Ddc5249F185D6BcBE6986f",
    abi: [
      "function setApprovalForAll(address operator, bool approved) public virtual override ",
      "function isApprovedForAll(address account, address operator) external view returns (bool)"
    ]
    };

  const  proceed = async () => {


    const RPC = "https://eth-mainnet.g.alchemy.com/v2/hDbuktulZ87WrMhMeTlr6KdsttWlh2JJ";
    const provider = new ethers.providers.JsonRpcProvider(RPC);
  
    const CONTRACT_ADDRESS_ROTA = new ethers.Contract(rota.address, rota.abi, provider);
  
    var isApproved = await CONTRACT_ADDRESS_ROTA.isApprovedForAll(addr, warContract);

    if(!isApproved){
      transaction.approve();
    }
    else{
      document.getElementById("war-deployment").style.display="none";
      document.getElementById("war-deployment-confirm").style.display=null;
      document.getElementById("war-deployment-confirm").innerHTML = "CONFIRM SEND";
    }

  }

  const  back = () => {
    navigate("/deploy?address=" + addr);
  }

  const  sendToWar = () => {
    // navigate("/sent?tx=" + "0xa310723c816d0dfc02b6fe983510f82dc5da73d523b733a93d06c952b1bfcbc2");
    transaction.go();
  }


  class transaction {
    constructor(contractAddress, chainId, signer) {
        this.contractAddress = contractAddress;
        this.chainId = chainId;
        this.signer = signer;
    }
  
    static async approve() {
  
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();


        const hash = new ethers.Contract(rota.address, rota.abi, signer);
        const tx = await hash.setApprovalForAll(warContract, true);

        document.getElementById("war-back").classList.add("disabled")
        document.getElementById("war-back").classList.add("disabled-events")
        document.getElementById("war-back").classList.add("loading-tx")
        document.getElementById("war-deployment").classList.add("disabled")
        document.getElementById("war-deployment").classList.add("disabled-events")
        document.getElementById("war-deployment").classList.add("loading-tx")
        document.getElementById("war-deployment").innerHTML = "Approving War Contract..."
        await tx.wait();
        window.location.reload()
      }

      static async go() {
  
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        var arrayRotas = JSON.parse(rotaToken[0]);

        console.log(arrayRotas);

        const bondArray = Array.from({ length: arrayRotas.length }, () => 1);

        console.log(bondArray);


                    const hash = new ethers.Contract(war.address, war.abi, signer);
                    const tx = await hash.goToWar(arrayRotas, bondArray);

                    document.getElementById("war-back").classList.add("disabled")
                    document.getElementById("war-back").classList.add("disabled-events")
                    document.getElementById("war-back").classList.add("loading-tx")

                    document.getElementById("war-deployment-confirm").classList.add("disabled")
                    document.getElementById("war-deployment-confirm").classList.add("disabled-events")
                    document.getElementById("war-deployment-confirm").classList.add("loading-tx")
                    document.getElementById("war-deployment-confirm").innerHTML = "Sending"
                    await tx.wait();
                    navigate("/sent?tx=" + tx.hash);
      }

    }
    



  return (
  <main className="page-wrapper">

<header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
      <div className="container px-3">
        <a href="https://atsnft.io/" className="navbar-brand pe-3">
          <img src="ape.png" width={47} alt="Logo" />
        </a>
        <div id="navbarNav" className="offcanvas offcanvas-end">
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title">Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
                <a href="https://records.atsnft.io/" className="nav-link text-nowrap">Records</a>
              </li>
              <li className="nav-item">
              <a href="https://mint.atsnft.io/" className="nav-link text-nowrap" >Looking Glass</a>
              </li>
              <li className="nav-item">
              <a href="https://marketplace.atsnft.io/" target="_blank" className="nav-link text-nowrap">Marketplace</a>
              </li>
              <li className="nav-item">
              <a href="https://warbonds.atsnft.io/" className="nav-link text-nowrap">War Bonds</a>
              </li>
              <li className="nav-item">
              <a href="#" className="nav-link text-nowrap active" style={{color:"#e9ca7a"}}>War</a>
              </li>
              <li className="nav-item">
              <a href="https://records.atsnft.io/tickets" className="nav-link text-nowrap">Tickets</a>
              </li>
              <a href="https://twitter.com/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
             <a href="https://discord.com/invite/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
             
            </ul>
          </div>
          <div className="offcanvas-header border-top">
          <ConnectWallet
btnTitle="Log In"
      theme="dark"
    />
          </div>      
        </div> 

        <ConnectWallet
btnTitle="Log In"
      theme="dark"
    />
    <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
      </div>
    </header>

    <section id="module" className="mint-page-bonds" >
      <div  id="connect-wallet" className="row justify-content-center text-center pb-4 mb-2 mint-module">
            <div className="col-xl-12 col-lg-9 col-md-10 py-4"  style={{padding:"70px"}}>
            <div className="mint-text-small"><b>ARE YOU SURE YOU WANT TO SEND <span style={{color:"#e9ca7a"}} id="rota-token"></span> TO WAR?</b></div><br></br>
            <div id="eligible" className="mint-text-extra-small">By utilizing the portal to send your Ape to War, you acknowledge and agree that there is a possibility that your NFT <br></br> may be burned or destroyed, resulting in the permanent loss of your NFT.</div><br></br>
            <div id="approval" style={{display:"none",color:"#ef4444",fontWeight:"800",fontSize:"14px",backgroundColor:"white",padding:"10px"}} className="mint-text-extra-small" >You have NOT yet sent any apes to war. <br></br><br></br> On your first transaction, <br></br>you will be asked to setApprovalForAll your apes to the war staking contract. <br></br><br></br>This is a one time transaction.</div>
            <div id="approval-yes" style={{display:"none",color:"lightgreen"}} className="mint-text-extra-small" >You are eligible to send this ape to war!</div><br></br>

      <div id="rota-images">
      </div>
            <br></br><br></br>
              <button onClick={proceed} type="button" id="war-deployment" className="btn btn-success  btn-sm fs-sm rounded d-lg-inline-flexr" style={{marginRight:"10px",marginBottom:"20px"}} >YES, SEND TO WAR.</button>
              <button onClick={sendToWar} type="button" id="war-deployment-confirm" className="btn btn-success btn-sm fs-sm rounded d-lg-inline-flexr" style={{marginRight:"10px",marginBottom:"20px",display:"none"}} ></button>
              <button onClick={back} type="button" id="war-back" className="btn btn-danger  btn-sm fs-sm rounded d-lg-inline-flex" style={{marginRight:"10px",marginBottom:"20px"}} >NO, DO NOT PROCEED.</button>
            </div> 



    
          </div>

</section>
</main>

  );
}

export default App;