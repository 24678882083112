import '../App.css';
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

import { ethers } from "ethers"
import {
  useAddress,
  ConnectWallet,
} from "@thirdweb-dev/react";

import axios from 'axios';

function App() {

  const [searchParams] = useSearchParams();
  const getAddress = useState(searchParams.get("address"));

  const myDirectory = useRef(null);

  useEffect(() => {
    if (getAddress[0] === "" || getAddress[0] === null) {
      navigate("/")
    }
    loadTokens(getAddress);
  }, []);

  /* global BigInt */

  const navigate = useNavigate();

  const [mintvalue, setMintValue] = useState('');
  const [voucher, setVoucher] = useState('');
  const [allowed, setAllowed] = useState('');
  const [addr, setAddress] = useState('');

  const [mintvalueReserved, setMintValueReserved] = useState('');
  const [mode, setMode] = useState('');

  const address = useAddress();

  useEffect(() => {

    if (address) {
      setAddress(address);
    }
  }, [address]);

  const previousAddressRef = useRef();
  useEffect(() => {
    previousAddressRef.current = address;
  }, [address]);
  const previousAddress = previousAddressRef.current;

  useEffect(() => {
    if (previousAddress && !address) {
      navigate("/");
    }
  }, [address, previousAddress]);



  const apes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  const apeURI = "https://s3.us-east-1.amazonaws.com/publicassets.atsnft.io/rota/images/"
  const warContract = "0x259C2FF4e10e7e2B31A2045c93eD8513299c8E04";

  var loaded = 0;

  var warTime = 172800;
  // var warTime = 0;

  const rota = {
    address: "0xDfB29501b42f63A947Ddc5249F185D6BcBE6986f",
    abi: [
      "function balanceOf(address owner) public view virtual override returns (uint256)",
      "function ownerOf(uint256 tokenId) public view virtual override returns (address)"
    ]
  };

  const staking = {
    address: warContract,
    abi: [
      "function getTokenDataFor(uint256[] calldata tokenIds) public view returns (uint40[] memory, uint40[] memory, uint8[] memory, address[] memory)"
    ]
  };

  const warbonds = {
    address: "0xcAcb0a5bb1f52F00a14bDA0dc85dE81392B2892B",
    abi: [
      "function balanceOf(address account, uint256 id) public view virtual override returns (uint256)"
    ]
  };

  async function loadTokens(addr) {

    var addressToLoad = addr[0];

    // var addressToLoad = "0xD96e4656f8906b215b2CA71A785ACE94E1Fa278b"

    if (addressToLoad === null || addressToLoad === undefined) {
      addressToLoad = addr;
    }

    if (loaded == 0 && addressToLoad.length > 0) {

      const RPC = "https://eth-mainnet.g.alchemy.com/v2/hDbuktulZ87WrMhMeTlr6KdsttWlh2JJ";
      const provider = new ethers.providers.JsonRpcProvider(RPC);

      const CONTRACT_ADDRESS_ROTA = new ethers.Contract(rota.address, rota.abi, provider);
      const CONTRACT_ADDRESS_STAKING = new ethers.Contract(staking.address, staking.abi, provider);

      myDirectory.current.innerHTML = "";

      var x = 0;
      var y = 0;

      const results = await axios.post(
        `https://api.thegraph.com/subgraphs/name/0x-g/rota-tracker`, {

        query: `{
            tokens(first:1000 orderBy: token, orderDirection: asc, where:{staked:true owner_contains_nocase:"` + addressToLoad + `"}) {
              id
              token
              owner
              staked
            }
          }`
      });

      var tokens = results.data.data.tokens;

      for (var i = 0; i < tokens.length; i++) {

        var data = await CONTRACT_ADDRESS_STAKING.getTokenDataFor([tokens[i].token]);

        var currentTime = Math.floor(Date.now() / 1000);
        var releaseTime = Number(data[0][0]) + Number(warTime)
        var secondsLeft = (releaseTime - currentTime);

        x = x + 1;

        if (secondsLeft > 0) {

          var hours = Math.floor(secondsLeft / 3600);
          var minutes = Math.floor((secondsLeft % 3600) / 60);
          var remainingSeconds = secondsLeft % 60;

          myDirectory.current.innerHTML +=
            ` <a class="disabled-events-image" style="text-decoration:none" href="war?rota=` + tokens[i].token + `">
              <div style="border: 3.5px solid black;margin:20px;height:35px" className="text-center px-1 col">
                <div className="d-flex justify-content-center text-center pe-xl-3">
                  <div className="justify-content-center text-center ps-4 ps-sm-3 ps-md-4">
                    <h3 class="text-center" style="font-size:18px">ROTA #` + tokens[i].token + `</h3>
                  </div>
                </div>
                <div class="card-body pb-4">
                <h3 class="h5" style="color:red;font-size:14px">
                 ` + hours + "h " + minutes + "m " + remainingSeconds + "s" + `
                </h3>
              </div>
              </div>
              </a>
              
              `


          // }, 1000);




        }
        else {

          y = y + 1;

          myDirectory.current.innerHTML +=
            `<a style="text-decoration:none" href="war?rota=` + tokens[i].token + `">
          <div style="border: 3.5px solid black;margin:20px;height:35px" className="text-center px-1 col">
            <div className="d-flex justify-content-center text-center pe-xl-3">
              <div className="justify-content-center text-center ps-4 ps-sm-3 ps-md-4">
                <h3 class="text-center" style="font-size:18px">ROTA #` + tokens[i].token + `</h3>
              </div>
            </div>
          </div>
          </a>`
        }
      }

    }
    loaded = 1;

    document.getElementById("total-rota-war").classList.remove("text-loading")
    document.getElementById("total-rota-war").innerHTML = x
    document.getElementById("total-rota-ready").classList.remove("text-loading")
    document.getElementById("total-rota-ready").innerHTML = y;

    if (x === 0) {
      document.getElementById("loading").style.display = "none";
      document.getElementById("refresh").classList.add("remove")
    }
    else {
      document.getElementById("loading").style.display = null;
      document.getElementById("refresh").classList.remove("remove")
      document.getElementById("loading").innerHTML = "SELECT ROTA TO SEND HOME:"
    }

  }

  const COST = 100000000000000;
  const COST_ETH = 0.0001;
  const PAYMENTFEE = 600000000000000;
  const PAYMENTFEE_ETH = 0.0006;
  const id = 1;

  async function getStats(address) {

    const RPC = "https://eth-mainnet.g.alchemy.com/v2/hDbuktulZ87WrMhMeTlr6KdsttWlh2JJ";
    const provider = new ethers.providers.JsonRpcProvider(RPC);

    const CONTRACT_ADDRESS_ROTA = new ethers.Contract(rota.address, rota.abi, provider);
    const CONTRACT_ADDRESS_WARBONDS = new ethers.Contract(warbonds.address, warbonds.abi, provider);

    var rotaBal = await CONTRACT_ADDRESS_ROTA.balanceOf(address);
    var warbondsBal = await CONTRACT_ADDRESS_WARBONDS.balanceOf(address, 1);

    document.getElementById("total-warbonds").innerHTML = warbondsBal;

    if (warbondsBal === 0) {
      document.getElementById("war-deployment").classList.add("disabled-header");
    }
    else {
      document.getElementById("war-deployment").classList.remove("disabled-header");
    }

    if (rotaBal === 0) {
      document.getElementById("war-deployment").classList.add("disabled-header");
    }
    else {
      document.getElementById("war-deployment").classList.remove("disabled-header");
    }
  }

  class transaction {
    constructor(contractAddress, chainId, signer) {
      this.contractAddress = contractAddress;
      this.chainId = chainId;
      this.signer = signer;
    }

    static async claim() {

      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      const connectedAddr = await signer.getAddress();

      const eth = await provider.getBalance(signer.getAddress());
      let ethbalance = parseInt(eth) / 1000000000000000000;

      if (ethbalance <= PAYMENTFEE_ETH) {
        document.getElementById("mint-button").innerHTML = "Insufficient Gas Funds"
        document.getElementById("mint-button").classList.add("disabled")
        document.getElementById("mint-button").style.color = "black";
      }
      if (voucher === undefined) {
        document.getElementById("mint-button").innerHTML = "Not on Allowlist"
        document.getElementById("mint-button").classList.add("disabled")
        document.getElementById("mint-button").style.color = "black";
      }
      else {
        console.log(voucher);
        console.log(allowed);
        // const hash = new ethers.Contract(contract.address, contract.abi, signer);
        // const tx = await hash.claimTokensWithAllowlist(voucher, id, allowed);
        document.getElementById("mint-button").classList.add("disabled")
        document.getElementById("mint-button").innerHTML = "Claiming..."
        document.getElementById("mint-button").style.color = "black";
        document.getElementById("mint-button").innerHTML = "Claiming..."
        document.getElementById("mint-button").removeAttribute("onclick")
        // await tx.wait();
        document.getElementById("claimed").innerHTML = "Success!"
        getStats(connectedAddr);
      }
    }

    static async claim() {

      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      const connectedAddr = await signer.getAddress();

      const eth = await provider.getBalance(signer.getAddress());
      let ethbalance = parseInt(eth) / 1000000000000000000;

      if (ethbalance <= PAYMENTFEE_ETH) {
        document.getElementById("mint-button").innerHTML = "Insufficient Gas Funds"
        document.getElementById("mint-button").classList.add("disabled")
        document.getElementById("mint-button").style.color = "black";
      }
      if (voucher === undefined) {
        document.getElementById("mint-button").innerHTML = "Not on Allowlist"
        document.getElementById("mint-button").classList.add("disabled")
        document.getElementById("mint-button").style.color = "black";
      }
      else {
        console.log(voucher);
        console.log(allowed);
        // const hash = new ethers.Contract(contract.address, contract.abi, signer);
        // const tx = await hash.claimTokensWithAllowlist(voucher, id, allowed);
        document.getElementById("mint-button").classList.add("disabled")
        document.getElementById("mint-button").innerHTML = "Claiming..."
        document.getElementById("mint-button").style.color = "black";
        document.getElementById("mint-button").innerHTML = "Claiming..."
        document.getElementById("mint-button").removeAttribute("onclick")
        // await tx.wait();
        document.getElementById("claimed").innerHTML = "Success!"
        getStats(connectedAddr);
      }
    }

  }


  const deploy = () => {
    navigate("/deploy?address=" + addr)
  }

  const view = () => {
    navigate("/return?address=" + addr)
  }

  function delay(n) {
    return new Promise(function (resolve) {
      setTimeout(resolve, n * 500);
    });
  }

  const [rotaId, setRotaId] = useState('');

  const send = async () => {

    const RPC = "https://eth-mainnet.g.alchemy.com/v2/hDbuktulZ87WrMhMeTlr6KdsttWlh2JJ";
    const provider = new ethers.providers.JsonRpcProvider(RPC);
    try {
      const CONTRACT_ADDRESS_ROTA = new ethers.Contract(rota.address, rota.abi, provider);

      var owner = await CONTRACT_ADDRESS_ROTA.ownerOf(rotaId);


      if (owner === addr) {
        navigate("/confirm?rota=" + rotaId);
      }
      else {
        document.getElementById("select").value = ""

        document.getElementById("select").placeholder = "You do not own this ROTA";
      }
    }
    catch (e) {
      document.getElementById("select").value = ""
      document.getElementById("select").placeholder = "You do not own this ROTA";
    }

  }

  const refresh = () => {
    window.location.reload();
  }




  return (
    <main className="page-wrapper">

      <header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
        <div className="container px-3">
          <a href="https://atsnft.io/" className="navbar-brand pe-3">
            <img src="ape.png" width={47} alt="Logo" />
          </a>
          <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
              <h5 className="offcanvas-title">Menu</h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a href="https://records.atsnft.io/" className="nav-link text-nowrap">Records</a>
                </li>
                <li className="nav-item">
                  <a href="https://mint.atsnft.io/" className="nav-link text-nowrap" >Looking Glass</a>
                </li>
                <li className="nav-item">
                  <a href="https://marketplace.atsnft.io/" target="_blank" className="nav-link text-nowrap">Marketplace</a>
                </li>
                <li className="nav-item">
                  <a href="https://warbonds.atsnft.io/" className="nav-link text-nowrap">War Bonds</a>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link text-nowrap active" style={{ color: "#e9ca7a" }}>War</a>
                </li>
                <li className="nav-item">
                  <a href="https://records.atsnft.io/tickets" className="nav-link text-nowrap">Tickets</a>
                </li>
                <a href="https://twitter.com/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
                <a href="https://discord.com/invite/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>

              </ul>
            </div>
            <div className="offcanvas-header border-top">
              <ConnectWallet
                btnTitle="Log In"
                theme="dark"
              />
            </div>
          </div>
          <ConnectWallet
            btnTitle="Log In"
            theme="dark"
          />
          <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </header>

      <section id="module" className="mint-page-war" >
        <section className="container d-md-flex align-items-center justify-content-between pb-3 py-5">

          <nav className="container mint-module">

            <div className="d-flex justify-content-left pt-4 py-5" >
              <button onClick={deploy} type="button" id="war-deployment" className="btn btn-sm fs-sm rounded d-lg-inline-flex" style={{ marginRight: "10px" }} >DEPLOY APES</button>
              <button onClick={view} type="button" className="btn btn-success btn-sm fs-sm rounded d-lg-inline-flex disabled-events" style={{ marginRight: "10px" }} >VIEW DEPLOYED</button>
            </div>

            <div className="d-flex justify-content-left" >
              <h4>YOU HAVE <span id="total-rota-war" className="text-loading" style={{ color: "lightgreen" }}>...</span> ROTA AT WAR</h4>
            </div>

            <div className="d-flex justify-content-left" >
              <h4>YOU HAVE <span id="total-rota-ready" className="text-loading" style={{ color: "lightgreen" }}>...</span> ROTA TO RETURN</h4>
            </div>

            <button id="refresh" onClick={refresh} type="button" className="btn btn-info btn-sm fs-sm rounded d-lg-inline-flex remove" style={{ marginBottom: "30px" }} >REFRESH TIMERS</button>


            <div className="d-flex justify-content-left py-3" >
              <h4 id="loading">LOADING WAR<span className="text-loading">...</span></h4>
            </div>

            <section ref={myDirectory} className="row row-cols-lg-4 row-cols-sm-2 row-cols-1 gy-md-4 gy-2 py-4">

            </section>

          </nav>

        </section>
      </section>

    </main>


  );
}

export default App;
